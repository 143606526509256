<!-- 数据列表 -->
<template>
  <div class="mainContentArea">
    <!-- 查询区域 -->
    <div class="queryBox">
      <div class="database" v-show="!isFoldQuery">
        <div class="cur_db">
          <span>
            当前数据库：
            <!-- <span>{{currentDB.name}}</span> -->
            <el-select
              size="mini"
              v-model="currentDB.id"
              @change="selectDB"
              ref="dbdom"
              class="cur_db_select">
              <el-option
                v-for="item in databaseOptions"
                :key="item.id"
                :label="item.name + ' (' + item.id + ')'"
                :value="item.id"></el-option>
            </el-select>
          </span>
        </div>
      </div>
      <div class="query_row" v-show="!isFoldQuery">
        <div class="scan_box">
          <span class="sp_scan">平台：</span>
          <!-- <el-input v-model="queryForm.platform" size="mini" class="ip_scan_sort" @input="queryHandle"></el-input> -->
          <el-select
            v-model="queryForm.platform"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option
              v-for="l in $co.pltOptions"
              :key="l.value"
              :label="l.label"
              :value="l.label"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">低价状态：</span>
          <el-select
            v-model="queryForm.is_low"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option
              v-for="l in isLowStatusOptions"
              :key="l.value"
              :label="l.label"
              :value="l.value"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">链接状态：</span>
          <el-select
            v-model="queryForm.link_status"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option
              v-for="l in linkStatusOptions"
              :key="l.value"
              :label="l.label"
              :value="l.value"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">SKU状态：</span>
          <el-select
            v-model="queryForm.sku_status"
            size="mini"
            multiple
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option
              v-for="l in skuStatusOptions"
              :key="l.value"
              :label="l.label"
              :value="l.value"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">更新状态：</span>
          <el-select
            v-model="queryForm.is_update"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option
              v-for="l in isUpdateOptions"
              :key="l.value"
              :label="l.label"
              :value="l.value"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">店铺类型：</span>
          <el-select
            v-model="queryForm.is_white"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option label="非白名单" value="0"></el-option>
            <el-option label="白名单" value="1"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">型号：</span>
          <el-select
            v-model="queryForm.sku_num"
            filterable
            allow-create
            default-first-option
            size="mini"
            clearable
            class="ip_scan_sort"
            @input="queryHandle"
            multiple>
            <el-option
              v-for="(md, ind) in qModeList"
              :key="ind"
              :label="md.label"
              :value="md.value">
            </el-option>
          </el-select>
          <!-- <el-input v-model="queryForm.sku_num" size="mini" clearable class="ip_scan_sort" @input="queryHandle"></el-input> -->
        </div>
        <div class="scan_box">
          <span class="sp_scan">标签：</span>
          <el-select
            v-model="queryForm.low_label_id"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option label="空标签" value="空标签"></el-option>
            <el-option
              v-for="l in labelList"
              :key="l.value"
              :label="l.label"
              :value="l.value"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">关注度：</span>
          <el-select
            v-model="queryForm.is_follow"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option label="一般" value="0"></el-option>
            <el-option label="重点关注" value="1"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">数据属性：</span>
          <el-select
            v-model="queryForm.label"
            size="mini"
            clearable
            class="ip_scan_sort"
            @change="queryHandle">
            <el-option label="默认" value="0"></el-option>
            <el-option label="无关项" value="1"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">商品ID：</span>
          <el-input
            v-model.trim="queryForm.goods_id"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">商品品牌：</span>
          <el-input
            v-model.trim="queryForm.goods_brand"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">店铺名称：</span>
          <el-input
            v-model.trim="queryForm.shop_name"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">掌柜昵称：</span>
          <el-input
            v-model.trim="queryForm.shopkeeper_nick"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">商品名称：</span>
          <!-- <el-input v-model.trim="queryForm.goods_title" size="mini" class="ip_scan_sort" @input="queryHandle"></el-input> -->
          <el-select
            v-model.trim="queryForm.goods_title"
            size="mini"
            multiple
            filterable
            allow-create
            default-first-option
            class="ip_scan_sort"
            @change="queryHandle">
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">SKU名称：</span>
          <!-- <el-input v-model.trim="queryForm.sku_att_class" size="mini" class="ip_scan_sort" @input="queryHandle"></el-input> -->
          <el-select
            v-model.trim="queryForm.sku_att_class"
            size="mini"
            multiple
            filterable
            allow-create
            default-first-option
            class="ip_scan_sort"
            @change="queryHandle">
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">SKU编号：</span>
          <el-input
            v-model.trim="queryForm.sku_id"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">关键词：</span>
          <el-input
            v-model.trim="queryForm.search_key"
            size="mini"
            class="ip_scan_sort"
            @input="queryHandle"></el-input>
        </div>
        <div class="scan_box">
          <span class="sp_scan">销量：</span>
          <el-select
            v-model.trim="queryForm.month_sale_num"
            size="mini"
            class="ip_scan_sort"
            clearable
            @change="queryHandle">
            <el-option label="有销量" value="1"></el-option>
            <el-option label="无销量" value="0"></el-option>
          </el-select>
        </div>
        <div class="scan_box">
          <span class="sp_scan">SKU库存：</span>
          <el-select
            v-model.trim="queryForm.skuCountType"
            size="mini"
            class="ip_scan_sort"
            clearable
            @change="queryHandle">
            <el-option label="有库存" value="0"></el-option>
            <el-option label="无库存" value="1"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 高级筛选区域 -->
      <div class="advanced_query" v-show="!isFoldQuery">
        <!-- <h3 class="ad_title">高级筛选</h3> -->
        <h3
          style="
            font-size: 16px;
            border-left: 4px solid #409eff;
            padding-left: 10px;
          ">
          高级筛选
        </h3>
        <div class="ad_query_row">
          <div class="ad_query_item">
            <span class="s_label">商品名称：</span>
            <el-select
              size="mini"
              v-model="queryForm.not_included"
              class="select"
              @change="queryHandle">
              <el-option label="筛选包含所填内容" value="1"></el-option>
              <el-option label="筛选不包含所填内容" value="0"></el-option>
            </el-select>
          </div>
          <div class="ad_query_item">
            <span class="s_label">SKU页面价：</span>
            <div class="input_s">
              <el-input
                size="mini"
                v-model="queryForm.sku_now_price_small"
                @input="queryHandle"
                placeholder="最小值"></el-input>
              <span class="mid">-</span>
              <el-input
                size="mini"
                v-model="queryForm.sku_now_price_large"
                @input="queryHandle"
                placeholder="最大值"></el-input>
            </div>
          </div>
          <div class="ad_query_item">
            <span class="s_label">SKU预估到手价：</span>
            <div class="input_s">
              <el-input
                size="mini"
                v-model="queryForm.sku_hand_price_small"
                @input="queryHandle"
                placeholder="最小值"></el-input>
              <span class="mid">-</span>
              <el-input
                size="mini"
                v-model="queryForm.sku_hand_price_large"
                @input="queryHandle"
                placeholder="最大值"></el-input>
            </div>
          </div>
          <div class="ad_query_item">
            <span class="s_label">商品页面价：</span>
            <div class="input_s">
              <el-input
                size="mini"
                v-model="queryForm.goods_now_price_small"
                @input="queryHandle"
                placeholder="最小值"></el-input>
              <span class="mid">-</span>
              <el-input
                size="mini"
                v-model="queryForm.goods_now_price_large"
                @input="queryHandle"
                placeholder="最大值"></el-input>
            </div>
          </div>
          <div class="ad_query_item">
            <span class="s_label">商品预估到手价：</span>
            <div class="input_s">
              <el-input
                size="mini"
                v-model="queryForm.goods_hand_price_small"
                @input="queryHandle"
                placeholder="最小值"></el-input>
              <span class="mid">-</span>
              <el-input
                size="mini"
                v-model="queryForm.goods_hand_price_large"
                @input="queryHandle"
                placeholder="最大值"></el-input>
            </div>
          </div>
          <div class="ad_query_item">
            <span class="s_label">更新时间：</span>
            <el-date-picker
              size="mini"
              type="daterange"
              v-model="queryForm.update_at"
              class="select"
              @change="queryHandle"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <div class="ad_query_item">
            <span class="s_label">创建时间：</span>
            <el-date-picker
              size="mini"
              type="daterange"
              v-model="queryForm.create_at"
              class="select"
              @change="queryHandle"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <div class="ad_query_item">
            <span class="s_label">价格排序：</span>
            <el-select
              v-model="queryForm.sku_hand_price_sort"
              size="mini"
              clearable
              class="select"
              placeholder="SKU预估到手价排序"
              @change="queryHandle">
              <el-option label="从低到高" value="0"></el-option>
              <el-option label="从高到低" value="1"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn_out_box" v-show="!isFoldQuery">
        <el-button type="primary" size="mini" @click="resetQuery"
          >重置查询</el-button
        >
        <el-button size="mini" type="primary" plain @click="labelVis = true"
          >标签管理</el-button
        >
        <el-button
          size="mini"
          type="primary"
          plain
          @click="OpenIrrelevantManage"
          >无关项管理</el-button
        >
        <el-button
          size="mini"
          type="primary"
          plain
          @click="databaseVisSign = !databaseVisSign"
          >数据库管理</el-button
        >
        <el-button size="mini" @click="infoHandle('batch')">批量操作</el-button>
        <div class="up_btn btn_items">
          <el-button size="mini">导入</el-button>
          <input
            type="file"
            class="upload"
            ref="refUpload"
            @change="importHandle" />
        </div>
        <el-button size="mini" @click="exportClickTag = !exportClickTag"
          >导出数据</el-button
        >
        <el-button size="mini" @click="download">下载导入模板</el-button>
        <el-button size="mini" @click="syncVis = true">同步</el-button>
        <el-button size="mini" @click="HandleUpdateSecondaryConfirm"
          >更新</el-button
        >
        <el-button size="mini" @click="linkTestHandle">链接检测</el-button>
        <el-button
          size="mini"
          @click="batchLink"
          type="warning"
          v-if="selectedList.length > 0"
          >批量查看链接</el-button
        >
        <el-tooltip effect="light" placement="top-start">
          <div class="tip_btn" slot="content">
            <div class="tip_items">
              <span class="t_title">同步按钮：</span>
              <span>根据白名单和控价表对当前数据列表进行更新；</span>
              <span>同时进行无关项匹配；</span>
            </div>
            <div class="tip_items">
              <span class="t_title">更新按钮：</span>
              <span>对当前数据列表的数据进行一次实时更新。</span>
            </div>
          </div>
          <el-button
            type="text"
            icon="el-icon-question"
            class="icon"></el-button>
        </el-tooltip>

        <div style="float: right">
          <el-button size="mini" type="warning" @click="stopUpdateHandle"
            >停止更新</el-button
          >
          <el-button size="mini" type="danger" @click="deleteHandle"
            >批量删除</el-button
          >
          <el-tooltip
            effect="dark"
            placement="bottom-start"
            content="自定义表头">
            <el-button
              type="text"
              @click="customHandle"
              icon="el-icon-setting"
              class="icon_color"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- 折叠按钮区域 -->
      <div class="fold_btn">
        <!-- 展开 -->
        <div
          class="f_open_area little_hands"
          @click="foldHandle"
          v-show="!isFoldQuery">
          <el-button
            type="text"
            icon="el-icon-arrow-up"
            class="btn_icon_s"></el-button>
          <p class="fold_content">收起筛选区域</p>
        </div>
        <!-- 折叠 -->
        <div class="f_fold_area" v-show="isFoldQuery">
          <h3>筛选区域</h3>
          <div class="f_f_content little_hands" @click="foldHandle">
            <p class="fold_content">展开筛选区域</p>
            <el-button
              type="text"
              icon="el-icon-arrow-down"
              class="btn_icon_s"></el-button>
          </div>
        </div>
      </div>
    </div>

    <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{
        width: 55,
        fixed: true,
      }"
      :getDataConfig="dataQueryFunc()"
      :totalCount="totalCount"
      :updateDataSignObj="{
        updateDataTag,
        resetPageNum,
      }"
      :selectConfig="{
        visible: true,
        width: '54',
        workInTable: false,
        isFixed: true,
        operationVis: true,
      }"
      :pageSizeConfig="{
        pageSizeOption: [50, 100, 300, 500],
        pageSize: 50,
      }"
      @multipleList="getMultipleList"
      @setResetPageNum="setRPN"
      :tableHeight="600">
      <el-table-column slot="handle" align="center" label="操作栏" width="80">
        <template slot-scope="scope">
          <div class="edit_icon">
            <el-button
              type="text"
              @click="infoHandle(scope.row)"
              icon="el-icon-edit-outline"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        slot="is_follow"
        align="center"
        label="关注度"
        width="80">
        <template slot-scope="scope">
          <el-tooltip
            content="一般"
            placement="top"
            v-if="scope.row.is_follow == '0'">
            <el-button
              @click="setFollow(scope.row, '1')"
              class="fontColor_info edit_icon"
              icon="el-icon-star-on"
              type="text"></el-button>
          </el-tooltip>
          <el-tooltip
            content="重点关注"
            placement="top"
            v-else-if="scope.row.is_follow == '1'">
            <el-button
              @click="setFollow(scope.row, '0')"
              class="fontColor_warning edit_icon"
              icon="el-icon-star-on"
              type="text"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        slot="data_attr"
        align="center"
        label="数据属性"
        width="100">
        <template slot-scope="scope">
          <div :class="scope.row.label == 1 ? 'fontColor_danger' : ''">
            {{ scope.row.label == 1 ? "无关项" : "默认" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="is_low" align="center" label="低价状态" width="80">
        <template slot-scope="scope">
          <div :class="scope.row.is_low_obj.className">
            {{ scope.row.is_low_obj.label }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="link_status"
        align="center"
        label="链接状态"
        width="80">
        <template slot-scope="scope">
          <div :class="scope.row.link_status_obj.className">
            {{ scope.row.link_status_obj.label }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="sku_status"
        align="center"
        label="SKU状态"
        width="80">
        <template slot-scope="scope">
          <div :class="scope.row.sku_status_obj.className">
            {{ scope.row.sku_status_obj.label }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="is_update"
        align="center"
        label="更新状态"
        width="80">
        <template slot-scope="scope">
          <div :class="scope.row.is_update_obj.className">
            {{ scope.row.is_update_obj.label }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="is_white"
        align="center"
        label="店铺类型"
        width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.is_white == '0'" class="fontColor_danger">
            非白名单
          </div>
          <div v-else-if="scope.row.is_white == '1'" class="fontColor_success">
            白名单
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="low_label_id"
        align="center"
        label="标签"
        width="200">
        <template slot-scope="scope">
          <div class="tag_style">
            <el-tag v-for="(l, idx) in scope.row.tag" :key="idx" size="small">
              {{ l }}
            </el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column slot="goods" align="center" label="商品信息" width="235">
        <template slot-scope="scope">
          <div class="g_info">
            <div class="g_pic">
              <el-image
                :src="scope.row.goods_pic"
                :preview-src-list="[scope.row.goods_pic]">
                <div slot="error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="table_stateBg g_right">
              <div style="text-align: left">ID：{{ scope.row.goods_id }}</div>
              <div>SKU：{{ scope.row.sku_id }}</div>
              <div class="bg_danger_light g_plt">{{ scope.row.platform }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="goods_title"
        align="center"
        label="商品名称"
        width="180">
        <template slot-scope="scope">
          <div class="g_title">
            <el-link
              :href="scope.row.goods_url_ult"
              type="primary"
              :underline="false"
              target="_blank"
              >{{ scope.row.goods_title }}</el-link
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column
        slot="goods_coupon"
        align="center"
        label="商品优惠券"
        width="140">
        <template slot-scope="scope">
          <div v-if="scope.row.goods_coupon.length < 15">
            <div>{{ scope.row.goods_coupon }}</div>
          </div>
          <el-popover
            v-else
            placement="top"
            width="200"
            trigger="hover"
            :content="scope.row.goods_coupon">
            <div slot="reference">
              {{ scope.row.goods_coupon.substr(0, 15) + "..." }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
    </tablePageComp>

    <!-- 同步操作时选择低价匹配类型 -->
    <el-dialog
      title="选择低价匹配类型"
      :visible.sync="syncVis"
      width="400px"
      custom-class="popup_style">
      <div class="operate_area">
        <div class="tips fontColor_danger" v-if="isBatch">
          Tips：批量修改信息时，将会覆盖掉所选数据原来的信息，请谨慎操作！
        </div>
        <div class="op_items">
          <label class="op_label">匹配类型：</label>
          <el-select v-model="matchParams.match_type" class="match_input">
            <el-option value="1" label="预估到手价"></el-option>
            <el-option value="2" label="商品页面价"></el-option>
          </el-select>
        </div>
        <div class="op_items">
          <label class="op_label">选择控价表：</label>
          <el-select v-model="matchParams.table_id" class="match_input">
            <el-option
              v-for="item in controlPriceList"
              :key="item.id"
              :value="item.table_id"
              :label="item.table_name"></el-option>
          </el-select>
        </div>
      </div>
      <div class="btn_box" slot="footer">
        <el-button size="small" type="primary" @click="syncHandle"
          >确定</el-button
        >
        <el-button size="small" @click="syncVis = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 标签管理弹窗 -->
    <el-dialog
      title="标签管理"
      :visible.sync="labelVis"
      width="500px"
      custom-class="popup_style">
      <div class="label_main">
        <h3 class="l_title">使用中的标签：</h3>
        <div class="l_box">
          <div class="label_items" v-for="item in labelList" :key="item.value">
            <el-tag
              closable
              :disable-transitions="false"
              @close="deleteLabel(item.value)">
              {{ item.label }}
            </el-tag>
          </div>
        </div>
        <!-- 添加标签 -->
        <div class="add_box">
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model.trim="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="addLabel"
            @blur="addLabel">
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ New Tag</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 修改信息窗口 -->
    <el-dialog
      :title="isBatch ? '批量修改' : '修改信息'"
      :visible.sync="modifyVis"
      width="600px"
      custom-class="popup_style">
      <div class="operate_area">
        <div class="tips fontColor_danger" v-if="isBatch">
          Tips：批量修改信息时，将会覆盖掉所选数据原来的信息，请谨慎操作！
        </div>
        <div class="op_items">
          <label class="op_label" for="db">所属数据库：</label>
          <el-select v-model="modifyForm.count_list" id="db" class="input">
            <el-option
              v-for="item in databaseOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="op_items">
          <label class="op_label" for="label">数据属性：</label>
          <el-select
            v-model="modifyForm.label"
            id="is_low"
            class="input"
            clearable>
            <el-option label="默认" value="0"></el-option>
            <el-option label="无关项" value="1"></el-option>
          </el-select>
        </div>
        <div class="op_items">
          <label class="op_label" for="label">标签：</label>
          <el-select
            v-model="modifyForm.low_label_id"
            placeholder="请选择标签"
            id="label"
            multiple
            class="input">
            <el-option
              v-for="item in labelList"
              :key="item.value"
              :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </div>
        <div class="op_items">
          <label class="op_label" for="s_sum">型号打标：</label>
          <el-autocomplete
            v-model="modifyForm.sku_num"
            placeholder="请选择或填入型号"
            :fetch-suggestions="modelSearch"
            id="s_sum"
            class="input">
          </el-autocomplete>
        </div>
        <div class="op_items">
          <label class="op_label" for="goods_att_class">监控SKU：</label>
          <el-input
            v-model="modifyForm.goods_att_class"
            id="goods_att_class"
            class="input">
          </el-input>
        </div>
        <div class="op_items">
          <label class="op_label" for="confirm_p">确认价格：</label>
          <el-input
            v-model="modifyForm.confirm_price"
            id="confirm_p"
            class="input">
          </el-input>
        </div>
        <div class="op_items">
          <label class="op_label" for="is_low">是否低价：</label>
          <el-select
            clearable
            v-model="modifyForm.is_low_price"
            id="is_low"
            class="input">
            <el-option label="非低价" value="非低价"></el-option>
            <el-option label="低价" value="低价"></el-option>
            <el-option label="已删除" value="已删除"></el-option>
            <el-option label="已下架" value="已下架"></el-option>
          </el-select>
        </div>
        <!-- <div class="op_items">
            <label class="op_label" for="label_one">预留表头1：</label>
            <el-input
            type="textarea"
            v-model="modifyForm.label_one" 
            id="label_one"
            class="input"
            >
            </el-input>
          </div>
          <div class="op_items">
            <label class="op_label" for="label_two">预留表头2：</label>
            <el-input
            type="textarea"
            v-model="modifyForm.label_two" 
            id="label_two"
            class="input"
            >
            </el-input>
          </div>
          <div class="op_items">
            <label class="op_label" for="label_three">预留表头3：</label>
            <el-input
            type="textarea"
            v-model="modifyForm.label_three" 
            id="label_three"
            class="input"
            >
            </el-input>
          </div> -->
        <div class="op_items">
          <label class="op_label" for="remarks">备注：</label>
          <el-input
            type="textarea"
            v-model="modifyForm.remarks"
            id="remarks"
            class="input">
          </el-input>
        </div>
      </div>
      <div class="btn_box" slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="subModify"
          :loading="btnLoading"
          >确定</el-button
        >
        <el-button size="small" @click="modifyVis = false" :loading="btnLoading"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑表头弹窗 -->
    <el-dialog
      title="自定义表头"
      :visible.sync="customVis"
      custom-class="popup_style"
      width="900px">
      <div>
        <el-transfer
          v-model="hasCheckedList"
          :titles="['可选表头', '当前表头']"
          :button-texts="['移除', '放入']"
          :data="tableHeaderList"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          target-order="push"
          @right-check-change="select">
          <div slot-scope="{ option }" class="hasChecked">
            <span class="option_label">{{ option.label }}</span>
            <!-- <span v-if="!option.disabled" class="option_width">宽度:<span class="o_w fontColor_theme">{{option.width || 100}}</span>px</span> -->
          </div>

          <el-button slot="right-footer" size="mini" @click="handleUp"
            >上移</el-button
          >
          <el-button slot="right-footer" size="mini" @click="handleDown"
            >下移</el-button
          >
        </el-transfer>
      </div>
      <div slot="footer" class="form_btn">
        <el-button
          size="small"
          type="primary"
          :loading="btnLoading"
          @click="setTableHead"
          >保存</el-button
        >
        <el-button size="small" @click="customVis = false" :loading="btnLoading"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 导出信息对话框 -->
    <ExportComponent
      :exportTypeSwitch="true"
      :exportDataAll="exportDataAll"
      :exportClickTag="exportClickTag"
      @getApiParams="getApiParamsFunc"
      apiName="exportDataListFunc"
      :defaultSelected="$co.defaultSelectedArr"></ExportComponent>

    <!-- 数据库管理 -->
    <DatabaseCom
      :databaseList="databaseOptions"
      :currentBrand="currentBrand"
      :dbView="databaseVisSign"
      @getAgain="getDatabaseAgain">
    </DatabaseCom>

    <!-- 链接检测结果弹窗 -->
    <el-dialog
      title="是否将检测结果更新至列表"
      :visible.sync="linkTestVis"
      width="600px"
      custom-class="popup_style">
      <div class="">
        <el-table
          :data="linkTestRes"
          style="width: 100%"
          border
          max-height="400">
          <el-table-column prop="goods_id" label="商品ID" width="120">
          </el-table-column>
          <el-table-column width="100" label="状态">
            <template slot-scope="scope">
              <div>
                {{
                  linkStatusOptions.filter(
                    (el) => el.value == scope.row.state
                  )[0].label
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="平台" width="100">
            <template slot-scope="scope">
              {{ scope.row.platform }}
            </template>
          </el-table-column>
          <el-table-column prop="url" label="链接地址"> </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button @click="linkTestVis = false" :loading="btnLoading"
          >取 消</el-button
        >
        <el-button type="primary" @click="subTestResult" :loading="btnLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 无关项管理窗口 -->
    <el-drawer
      :visible.sync="imVis"
      direction="ltr"
      :show-close="false"
      :size="700"
      :withHeader="false"
      :destroy-on-close="true">
      <irrelevantManageComVue
        :currentBrand="currentBrand"></irrelevantManageComVue>
    </el-drawer>

    <!-- 数据更新确认窗口 -->
    <el-dialog
      :visible.sync="updateConfirmationVis"
      width="300px"
      center
      title="目标数据是否已预处理">
      <div style="text-align: center">
        <el-select v-model="flag">
          <el-option label="未处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
        </el-select>
      </div>

      <span slot="footer">
        <el-button @click="updateConfirmationVis = false" size="mini"
          >取消</el-button
        >
        <el-button type="primary" @click="updateHandle" size="mini"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DatabaseCom from "@/components/tool/databaseCom.vue";
import irrelevantManageComVue from "../taskSubmission/components/irrelevantManageCom.vue";

export default {
  name: "dataList",
  components: {
    DatabaseCom,
    irrelevantManageComVue,
  },
  props: {
    // 当前品牌
    currentBrand: {
      type: Number,
    },
    databaseOptions: {
      type: Array,
    },
    // 控价表库
    controlPriceList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    // let db = JSON.parse(this.utils.sessionGetStore('databaseOptions'))
    return {
      // 表格
      dataList: [],
      updateDataTag: false,
      resetPageNum: true,
      totalCount: 0,
      // 查询
      queryForm: {
        is_low: "", // 低价状态 0-未确定 1-非低价 2-低价  6-失败
        link_status: "", // 链接状态 0-正常 1-已下架 2-已删除
        sku_status: [], // SKU状态    0-正常   1-无效  2-新增
        is_update: "", // 更新状态 0-完成 1-待更新 2-更新中 3-维护
        is_white: "", // 店铺类型 0-非白名单 1-白名单
        low_label_id: "", // 标签 参数所需格式：',1,2,3,'
        // sku_num: '',// 型号
        sku_num: [], // 型号-多选
        platform: "",
        is_follow: "",
        label: "0", // 0-默认 1-无关项
        goods_id: "",
        shopkeeper_nick: "",
        goods_brand: "",
        shop_name: "",
        goods_title: [],
        sku_att_class: [],
        sku_id: "",
        search_key: "", // 关键词
        month_sale_num: "", // 销量
        skuCountType: "", // 库存
        not_included: "1", //筛选商品标题 是否包含0不包含,1包含

        // 价格区间
        sku_now_price_large: "", // sku页面价格
        sku_now_price_small: "",
        sku_hand_price_large: "", // sku预估到手价
        sku_hand_price_small: "",
        goods_now_price_large: "", // 商品页面价,
        goods_now_price_small: "",
        goods_hand_price_large: "", //商品预估到手价
        goods_hand_price_small: "",
        sku_hand_price_sort: "", // sku预估到手价排序

        timeSort: "", // 更新时间 1-正序 2-倒序
        update_at: [],
        create_at: [],
      },
      // 多选字段及时间区间待处理字段
      multipleKeySettle: [
        "update_at",
        "create_at",
        "goods_title",
        "sku_att_class",
        "sku_num",
        "sku_status",
      ],
      // 低价状态
      isLowStatusOptions: [
        {
          label: "未确定",
          value: "0",
          className: "fontColor_info",
        },
        {
          label: "非低价",
          value: "1",
          className: "fontColor_success",
        },
        {
          label: "低价",
          value: "2",
          className: "fontColor_danger",
        },
        // {
        //   label: '正常',
        //   value: '3',
        //   className: 'fontColor_success'
        // },
        // {
        //   label: '已下架',
        //   value: '4',
        //   className: 'fontColor_info'
        // },
        // {
        //   label: '已删除',
        //   value: '5',
        //   className: 'fontColor_info'
        // },
        // {
        //   label: '失败',
        //   value: '6',
        //   className: 'fontColor_danger'
        // },
      ],
      // 链接状态
      linkStatusOptions: [
        {
          label: "正常",
          value: "0",
          className: "fontColor_success",
        },
        {
          label: "已下架",
          value: "1",
          className: "fontColor_info",
        },
        {
          label: "已删除",
          value: "2",
          className: "fontColor_info",
        },
        // {
        //   label: "待更新",
        //   value: "3",
        //   className: "fontColor_warning",
        // },
        // {
        //   label: "更新中",
        //   value: "4",
        //   className: "fontColor_warning",
        // },
        // {
        //   // label: "维护中",
        //   label: "失败",
        //   value: "5",
        //   className: "fontColor_info",
        // },
        // {
        //   label: "新增",
        //   value: "6",
        //   className: "fontColor_theme",
        // },
        // {
        //   label: "暂未更新",
        //   value: "7",
        //   className: "fontColor_warning",
        // },
      ],
      isUpdateOptions: [
        {
          label: "完成",
          value: "0",
          className: "fontColor_success",
        },
        {
          label: "待更新",
          value: "1",
          className: "fontColor_warning",
        },
        {
          label: "更新中",
          value: "2",
          className: "fontColor_warning",
        },
        {
          label: "维护",
          value: "3",
          className: "fontColor_info",
        },
        {
          label: "暂未更新",
          value: "4",
          className: "fontColor_info",
        },
      ],
      skuStatusOptions: [
        {
          label: "正常",
          value: "0",
          className: "fontColor_success",
        },
        {
          label: "无效",
          value: "1",
          className: "fontColor_danger",
        },
        {
          label: "新增",
          value: "2",
          className: "fontColor_theme",
        },
      ],
      // 数据信息操作
      selectedList: [], // 已选择内容,如果勾选全部 = 'selectAll'
      labelList: [],
      labelVis: false, // 标签管理弹窗
      inputVisible: false,
      inputValue: "", // 新标签输入框内容
      modelList: [
        // 示例
        // { 'value': 't-100' },
        // {'value':'t-333'},
      ], // 型号输入建议-信息操作
      qModeList: [
        {
          label: "查看未打标",
          value: "未打标",
        },
        {
          label: "查看已打标",
          value: "已打标",
        },
      ],
      // 信息修改
      modifyVis: false,
      modifyForm: {
        sku_num: "", // 型号
        low_label_id: [], // 标签
        label: "",
        confirm_price: "", // 确认价格
        is_low_price: "", // 是否低价
        label_one: "", // 预留表头1
        label_two: "",
        label_three: "",
        remarks: "",
        goods_att_class: "", // 监控SKU
        current_id: "", // 当前数据的id ----单条修改时使用
        count_list: "", // 设置链接的所属数据库
      },
      isBatch: false, // 是否批量操作

      // 自定义表头相关
      customVis: false,
      customHeaderList: [
        // 示例
        // 'str1','str2'
      ], // 当前表头
      hasCheckedList: [], // 自定义弹窗内已选择内容
      rightSelected: "", // 右侧已选中内容
      tableHeaderList: [
        {
          label: "操作",
          slotName: "handle",
          key: "handle",
          disabled: true,
        },
        // {
        //   label: "关注度",
        //   slotName: "is_follow",
        //   key: "is_follow",
        //   disabled: true,
        // },
        {
          label: "数据属性",
          slotName: "data_attr",
          key: "label",
          disabled: true,
        },
        {
          label: "低价状态",
          slotName: "is_low",
          key: "is_low",
          disabled: true,
        },
        {
          label: "链接状态",
          slotName: "link_status",
          key: "link_status",
          disabled: true,
        },
        {
          label: "店铺类型",
          slotName: "is_white",
          key: "is_white",
          disabled: true,
          SPU: true, // spu版本导出标记
        },
        {
          label: "SKU状态",
          slotName: "sku_status",
          key: "sku_status",
          width: 100,
          disabled: true,
        },
        {
          label: "更新状态",
          slotName: "is_update",
          key: "is_update",
          width: 100,
          disabled: true,
        },
        {
          label: "商品信息",
          slotName: "goods",
          key: "goods",
          disabled: false,
        },
        {
          label: "商品名称",
          slotName: "goods_title",
          key: "goods_title",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "SKU名称",
          prop: "sku_att_class",
          width: 150,
          key: "sku_att_class",
          disabled: false,
        },
        {
          label: "SKU编号",
          prop: "sku_id",
          width: 120,
          key: "sku_id",
          disabled: false,
        },
        {
          label: "监控SKU",
          prop: "goods_att_class",
          width: 100,
          key: "goods_att_class",
          disabled: false,
        },
        {
          label: "监控价格",
          prop: "price_c",
          key: "price_c",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "型号",
          prop: "sku_num",
          width: 120,
          key: "sku_num",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "商品页面价格",
          prop: "goods_now_price",
          width: 100,
          key: "goods_now_price",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "商品预估到手价",
          prop: "goods_hand_price",
          width: 105,
          key: "goods_hand_price",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "确认价格",
          prop: "confirm_price",
          width: 100,
          key: "confirm_price",
          disabled: false,
        },
        {
          label: "SKU页面价",
          // prop: "sku_now_price",
          prop: "sku_old_price",
          width: 100,
          // key: "sku_now_price",
          key: "sku_old_price",
          disabled: false,
        },
        {
          label: "SKU预估到手价",
          prop: "sku_hand_price",
          width: 110,
          key: "sku_hand_price",
          disabled: false,
        },
        {
          label: "是否低价",
          prop: "is_low_price",
          key: "is_low_price",
          disabled: false,
        },
        {
          label: "标签",
          slotName: "low_label_id",
          key: "low_label_id",
          disabled: false,
        },
        {
          label: "预留表头1",
          prop: "label_one",
          width: 120,
          key: "label_one",
          disabled: false,
        },
        {
          label: "预留表头2",
          prop: "label_two",
          width: 120,
          key: "label_two",
          disabled: false,
        },
        {
          label: "预留表头3",
          prop: "label_three",
          width: 120,
          key: "label_three",
          disabled: false,
        },
        {
          label: "备注",
          prop: "remarks",
          width: 200,
          key: "remarks",
          disabled: false,
        },
        {
          label: "店铺名称",
          prop: "shop_name",
          key: "shop_name",
          disabled: false,
          width: 120,
          SPU: true, // spu版本导出标记
        },
        {
          label: "掌柜昵称",
          prop: "shopkeeper_nick",
          width: 120,
          key: "shopkeeper_nick",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "销量",
          prop: "month_sale_num",
          width: 100,
          key: "month_sale_num",
          disabled: false,
          SPU: true, // spu版本导出标记
        },
        {
          label: "SKU库存",
          // label: "库存",
          prop: "sku_inv_count",
          // prop: "inv_count",
          width: 100,
          // key: "inv_count",
          key: "sku_inv_count",
          disabled: false,
        },
        {
          label: "创建时间",
          prop: "create_at",
          width: 140,
          key: "create_at",
          disabled: false,
        },
        {
          label: "更新时间",
          prop: "update_at",
          width: 140,
          key: "update_at",
          disabled: false,
        },
        {
          label: "发货地",
          prop: "place_of_delivery",
          width: 140,
          key: "place_of_delivery",
          disabled: false,
        },
        {
          label: "关键词",
          prop: "search_key",
          width: 100,
          key: "search_key",
          disabled: false,
        },
        {
          label: "运费",
          prop: "shipping",
          width: 100,
          key: "shipping",
          disabled: false,
        },
        {
          label: "商品品牌",
          prop: "goods_brand",
          width: 100,
          key: "goods_brand",
          disabled: false,
        },
        {
          label: "商品优惠券",
          slotName: "goods_coupon",
          key: "goods_coupon",
          disabled: false,
        },
        // {
        //   label: '店铺注册公司名称',
        //   prop: 'company_name',
        //   width: 140,
        //   key: 'company_name',
        //   disabled: false
        // },
        // {
        //   label: '店铺注册公司地址',
        //   prop: 'company_addr',
        //   width: 140,
        //   key: 'company_addr',
        //   disabled: false
        // },
      ], // 所有表头
      btnLoading: false,

      // 导出相关
      // 支持导出的所有字段
      // exportDataAll: [
      //   {
      //     chinese: "商品编号",
      //     english: "goods_id"
      //   },
      //   {
      //     chinese: "商品链接",
      //     english: "goods_url"
      //   },
      //   {
      //     chinese: "平台",
      //     english: "platform"
      //   },
      //   {
      //     chinese: "商品主图",
      //     english: "goods_pic"
      //   },
      //   {
      //       chinese: "店铺链接",
      //       english: "shop_url"
      //   },
      // ],
      exportClickTag: false,
      isFoldQuery: false, // 是否折叠筛选区域
      // 低价匹配
      matchParams: {
        match_type: "1", // 1(预估到手价),2(商品页面价)
        table_id: 1, // 控价表id 默认id为1---默认
      },
      syncVis: false,
      imVis: false,

      // 当前数据库
      currentDB: {
        // name: '',
        // id: ''
      },
      // databaseOptions: db, // 当前品牌数据库

      // 数据库管理组件展示标记
      databaseVisSign: false,
      testLink: "test content",
      linkTestVis: false,
      linkTestRes: [],
      linkLoading: null,

      flag: 0, // 目标更新数据是否已预处理：0-否 1-是
      updateConfirmationVis: false,
    };
  },
  watch: {
    currentBrand: function (val) {
      // 清除服务页面当前数据库缓存，显示默认
      this.utils.sessionRemoveStore("curDatabase");
      // 获取当前品牌的标签和表头
      this.gainLabelInfo();
      this.getTableHead();
      this.getModeList();
      this.setCurrentDB();

      // 清空批量选择结果
      // this.selectedList = []
      // bug: 数据库更新，也会改变更新标记，同时取反两次，导致监听到的标记值未变，页面不刷新
      // 品牌改变时，数据库配置肯定会更新，故在监听数据库时更新标记
      // this.updateDataTag = !this.updateDataTag
    },
    databaseOptions: function (val) {
      // 重设当前数据库
      this.setCurrentDB();
      this.updateDataTag = !this.updateDataTag;
    },
  },
  computed: {
    columnsList() {
      let arr = [];
      this.customHeaderList.forEach((ckey) => {
        let obj = JSON.parse(JSON.stringify(this.tableHeaderList));
        let records = obj.filter((item) => item.key == ckey)[0];
        if (records) {
          delete records["disabled"];
          delete records["key"];
          arr.push(records);
        }
      });
      return arr;
    },
    // 导出字段设置,保证与自定义表头顺序一致
    exportDataAll() {
      let includeArr = [],
        notIncludeArr = [], // 普通导出
        spuExportKey = [], // spu版本导出字段
        obj = JSON.parse(JSON.stringify(this.tableHeaderList)),
        defaultItems = [
          //  商品信息
          {
            chinese: "商品编号",
            english: "goods_id",
          },
          {
            chinese: "商品链接",
            english: "goods_url",
          },
          {
            chinese: "平台",
            english: "platform",
          },
          {
            chinese: "商品主图",
            english: "goods_pic",
          },
          {
            chinese: "店铺链接",
            english: "shop_url",
          },
        ];
      if (this.customHeaderList.length == 0) return;
      obj.forEach((item) => {
        if (
          this.customHeaderList.indexOf(item.key) == -1 &&
          item.key != "handle"
        ) {
          if (item.key == "goods") {
            notIncludeArr = notIncludeArr.concat(defaultItems);
          } else {
            notIncludeArr.push({
              chinese: item.label,
              english: item.key,
            });
          }
        }

        if (item.SPU) {
          spuExportKey.push({ chinese: item.label, english: item.key });
        }
      });
      this.customHeaderList.forEach((k) => {
        let ass = obj.filter((item) => item.key == k)[0];
        if (ass && ass.key != "handle") {
          if (k == "goods") {
            includeArr = includeArr.concat(defaultItems);
          } else {
            includeArr.push({
              chinese: ass.label,
              english: ass.key,
            });
          }
        }
      });

      // spu版本字段顺序
      let keySequence = [
        "平台",
        "商品编号",
        "商品链接",
        "商品名称",
        "店铺名称",
        "掌柜昵称",
        "店铺类型",
        "型号",
        "商品页面价格",
        "商品预估到手价",
        "监控价格",
        "销量",
        "商品主图",
        "店铺链接",
      ];
      let spuResult = [];
      spuExportKey = spuExportKey.concat(defaultItems);
      keySequence.forEach((key) => {
        let item = spuExportKey.filter((item) => item.chinese == key)[0];
        if (item) {
          spuResult.push(item);
        }
      });
      return {
        SKU: includeArr.concat(notIncludeArr),
        SPU: spuResult,
      };
    },
  },
  created() {
    this.gainLabelInfo();
    this.getTableHead();
    this.getModeList();
    this.setCurrentDB();

    // 导出可选字段设置
    // this.tableHeaderList.forEach(item => {
    //   if(item.key != 'handle' && item.key != 'goods') {
    //     this.exportDataAll.push({
    //       chinese: item.label,
    //       english: item.key
    //     })
    //   }
    // })

    // 查询条件
    this.queryForm =
      JSON.parse(this.utils.sessionGetStore("dataListQuery")) || this.queryForm;

    let that = this;
    // 监听chrome extensions 发回的消息
    window.addEventListener("message", function (e) {
      if (e.data.sign == "chrome") {
        // loading close
        that.linkTestVis = true;
        that.linkLoading.close();
        that.linkTestRes = [...JSON.parse(e.data.result)];
      }
    });
  },

  methods: {
    download() {
      window.location.href =
        "https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/%E4%BC%BD%E5%88%A9%E7%95%A5%E6%95%B0%E6%8D%AE%E5%88%97%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
    },
    // 批量查看链接
    batchLink() {
      let arr = [];
      if (this.selectedList == "selectAll") {
        if (this.dataList.length >= 50) {
          this.$message.warning("链接量过大，已阻止新开窗口");
          return;
        } else {
          arr = [...this.dataList];
        }
      } else {
        arr = [...this.selectedList];
      }
      arr.forEach((item) => {
        window.open(item.goods_url_ult);
      });
    },
    // 开启无关项管理窗口
    OpenIrrelevantManage() {
      this.imVis = true;
    },
    subTestResult() {
      let paramObj = [];
      this.linkTestRes.forEach((item) => {
        paramObj.push({
          id: item.id,
          linkState: item.state,
        });
      });
      this.btnLoading = true;
      this.$api
        .updateTestResult(paramObj)
        .then((res) => {
          // console.log(res)
          this.btnLoading = false;
          this.linkTestVis = false;
          this.updateDataTag = !this.updateDataTag;
          this.utils.notificationInfo("消息", "链接状态已更新", "success");
        })
        .catch((error) => {
          this.btnLoading = false;
          console.log(error);
        });
    },
    // 链接检测
    linkTestHandle() {
      if (!this.utils.sessionGetStore("hasPlug")) {
        this.$notify({
          title: "消息",
          dangerouslyUseHTMLString: true,
          message: `
          <div>
             <span>请先安装Chrome插件。</span>
             <a href="https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/galileo_chrome.zip">点此下载</a>
           </div>`,
          type: "warning",
        });
        return;
      }
      // console.log(this.selectedList)
      // 向插件发送消息
      this.linkLoading = null;
      if (this.selectedList.length === 0) {
        this.$message.warning("未选择任何链接");
      } else if (this.selectedList == "selectAll") {
        this.$message.warning("不支持勾选全部方式进行检测");
      } else {
        let error = this.selectedList.some(
          (item) => item.goods_url === "0" || item.goods_url == ""
        );
        if (error) {
          this.$message.warning("请勿选择空的商品链接");
          return;
        }
        let urlArr = [];
        this.selectedList.forEach((item) => {
          urlArr.push({
            id: item.id,
            url: item.goods_url,
            state: item.link_status,
            goods_id: item.goods_id,
            platform: item.platform,
          });
        });
        this.linkLoading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        window.postMessage({ url: urlArr, sign: "test" }, "*");
        this.utils.localSetStore("url", urlArr);
      }
    },
    getDatabaseAgain() {
      this.$emit("getDatabaseAgain");
    },

    triggerHandle() {
      this.$refs.dbdom.focus();
    },
    // 切换当前数据库
    selectDB() {
      this.currentDB.name = this.databaseOptions.filter(
        (item) => item.id == this.currentDB.id
      )[0].name;
      this.utils.sessionSetStore("curDatabase", this.currentDB);
      this.updateDataTag = !this.updateDataTag;
    },
    // 设置当前数据库
    setCurrentDB() {
      let cd = this.utils.sessionGetStore("curDatabase");
      if (cd) {
        this.currentDB = JSON.parse(cd);
      } else {
        let records = this.databaseOptions.filter(
          (item) => item.name == "默认"
        )[0];
        this.currentDB = {
          name: records.name,
          id: records.id,
        };
      }
    },
    // 折叠
    foldHandle() {
      this.isFoldQuery = !this.isFoldQuery;
    },
    HandleUpdateSecondaryConfirm() {
      this.updateConfirmationVis = true;
      this.flag = 0;
    },
    // 更新数据
    updateHandle() {
      // if(this.queryForm.platform == "") {
      //   this.$message.warning('更新数据前，请选择平台')
      //   return
      // }
      // this.$confirm("确认开始更新数据吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      // .then(() => {
      let paramObj = {
        brand_id: this.currentBrand,
        count_list: this.currentDB.id,
        login_id: this.utils.sessionGetStore("userId"),
        flag: this.flag,
        req: {
          count_list: this.currentDB.id,
        },
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (key == "low_label_id") {
          if (this.queryForm.low_label_id.length > 0) {
            // 标签可多选
            // paramObj.low_label_id = ',' + this.queryForm.low_label_id.join(',') + ','
            // 标签单选
            paramObj.req.low_label_id = "," + this.queryForm.low_label_id + ",";
          }
        }
        // 更新时间 商品名称 sku名称处理 型号
        else if (
          // key == "update_at" ||
          // key == "create_at" ||
          // key == "goods_title" ||
          // key == "sku_att_class" ||
          // key == "sku_num" ||
          // key == "sku_status"
          this.multipleKeySettle.includes(key)
        ) {
          if (this.queryForm[key] && this.queryForm[key].length > 0) {
            paramObj.req[key] = this.queryForm[key].join(",");
          }
        } else {
          if (this.queryForm[key]) {
            paramObj.req[key] = this.queryForm[key];
          }
        }
      });

      // 测试参数
      // paramObj.req = {...paramObj}
      // 更新api
      let pendingFunc = () => {
        const loading = this.$loading({
          lock: true,
          text: "正在加入更新队列，请勿刷新或关闭网页，请稍后……",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$api
          .updateDataListFunc(paramObj)
          .then((res) => {
            if (res.code === 0) {
              // this.utils.notificationInfo(
              //   "提示",
              //   `已提交更新数据请求,前面有${res.data.num}条数据在排队`,
              //   "success",
              //   5000
              // );
              this.utils.notificationInfo(
                "提示",
                `已提交更新数据请求,等待更新`,
                "success",
                5000
              );
              this.updateDataTag = !this.updateDataTag;
              this.updateConfirmationVis = false;
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$message.error("更新请求失败");
          })
          .finally(() => {
            loading.close();
          });
      };
      this.$api
        .beforeUpdateFunc(paramObj)
        .then((res) => {
          // console.log(res);
          if (res.data.notUpdateCount === 0) {
            pendingFunc();
          } else {
            this.$confirm(
              "队列中有未更新完成的数据，确认重新开始更新吗？",
              "提示",
              {
                type: "warning",
                lock: true,
              }
            ).then(() => {
              pendingFunc();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("更新前检查失败");
        });
      // });
    },
    // 设置关注度操作
    setFollow(row, type) {
      let paramObj = {
        id: row.id,
        count_list: this.currentDB.id,
        is_follow: type,
      };

      this.$api
        .modifyDataListFunc(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.resetPageNum = false;
            this.updateDataTag = !this.updateDataTag;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("设置失败");
        });
    },
    // 导入数据列表
    // 导入
    importHandle(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("brandId", this.currentBrand);
      formData.append("countList", this.currentDB.id);
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
        lock: true,
        text: "导入中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // this.$api.importDatalistFunc(formData)
      this.$api
        .importDatalistFunc_new(formData)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", res.msg, "success", 5000);
          } else {
            this.$message.warning(res.msg);
          }
          loading.close();
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    // 导出参数处理
    getApiParamsFunc(callBack) {
      let paramObj = {
        brand_id: this.currentBrand,
        count_list: this.currentDB.id,
        req: {},
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (key == "low_label_id") {
          if (this.queryForm.low_label_id.length > 0) {
            paramObj.req.low_label_id = "," + this.queryForm.low_label_id + ",";
          }
        }
        // 更新时间 商品名称 sku名称处理
        else if (
          // key == "update_at" ||
          // key == "create_at" ||
          // key == "goods_title" ||
          // key == "sku_att_class" ||
          // key == "sku_num" ||
          // key == "sku_status"
          this.multipleKeySettle.includes(key)
        ) {
          if (this.queryForm[key] && this.queryForm[key].length > 0) {
            paramObj.req[key] = this.queryForm[key].join(",");
          }
        } else {
          if (this.queryForm[key]) {
            paramObj.req[key] = this.queryForm[key];
          }
        }
      });

      callBack(paramObj);
    },
    // 右侧选中监听事件
    select(val, arr) {
      this.rightSelected = val;
    },
    // 上移
    handleUp() {
      let indexSelected = ""; // 当前选中项的下标
      if (this.rightSelected.length == 1) {
        this.hasCheckedList.find((val, index, arr) => {
          if (val == this.rightSelected[0]) {
            indexSelected = index;
          }
        });
        // 前5个表头位置固定
        if (indexSelected == 6) {
          this.$message.warning("没有上移的空间了");
          return;
        } else if (indexSelected) {
          let lead = JSON.parse(
            JSON.stringify(this.hasCheckedList[indexSelected])
          );
          this.hasCheckedList.splice(indexSelected, 1);
          this.hasCheckedList.splice(indexSelected - 1, 0, lead);
        }
      } else {
        this.$message.warning("只能选择一条内容进行移动！");
        return;
      }
    },
    // 下移
    handleDown() {
      let indexSelected = "";
      if (this.rightSelected.length == 1) {
        this.hasCheckedList.find((val, index, arr) => {
          if (val == this.rightSelected[0]) {
            indexSelected = index;
          }
        });
        if (indexSelected == this.hasCheckedList.length - 1) {
          this.$message.warning("没有下移的空间了");
          return;
        } else if (indexSelected) {
          let lead = JSON.parse(
            JSON.stringify(this.hasCheckedList[indexSelected])
          );
          this.hasCheckedList.splice(indexSelected, 1);
          this.hasCheckedList.splice(indexSelected + 1, 0, lead);
        }
      } else {
        this.$message.warning("只能选择一条内容进行移动！");
        return;
      }
    },
    // 自定义表头相关----开启窗口
    customHandle() {
      this.customVis = true;
      this.hasCheckedList = JSON.parse(JSON.stringify(this.customHeaderList));
      this.rightSelected = "";
    },
    // 获取当前自定义表头
    getTableHead(callBack) {
      let paramObj = {
        brand_id: this.currentBrand,
      };

      this.$api
        .customTableHead(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            let records = res.data.list.list_name;
            this.customHeaderList = records.split(",");
            if (callBack) {
              callBack();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setTableHead() {
      this.btnLoading = true;
      let paramObj = {
        brand_id: this.currentBrand,
        state: "2", // 默认2  修改
        list_name: this.hasCheckedList.join(","),
      };
      this.$api
        .setupTableHead(paramObj)
        .then((res) => {
          // console.log(res)

          this.getTableHead(() => {
            // this.customVis = false
            // this.btnLoading = false
            // this.updateDataTag = !this.updateDataTag
            // 列表重新渲染异常
            window.history.go(0);
          });
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$message.error("自定义失败");
        });
    },
    stopUpdateHandle() {
      this.$confirm("确认停止更新当前数据库吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "我再想想",
        type: "warning",
      }).then(() => {
        let paramObj = {
          brand_id: this.currentBrand,
          count_list: this.currentDB.id,
        };
        const loading = this.$loading({
          lock: true,
          text: "正在退出更新队列，请稍后",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$api
          .stopUpdateDataListFunc(paramObj)
          .then(() => {
            this.utils.notificationInfo("提示", "已停止", "success");
            this.updateDataTag = !this.updateDataTag;
          })
          .catch((error) => {
            console.log(error);
            this.$message.error("操作失败");
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 批量删除操作
    deleteHandle() {
      if (this.selectedList.length == 0) {
        this.$message.warning("还未选择任何信息");
        return;
      }

      this.$confirm("确认批量删除所选的数据信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "我再想想",
        type: "warning",
      }).then(() => {
        let paramObj = {};
        if (this.selectedList == "selectAll") {
          // 勾选全部
          // 加上后缀‘aa’，为了区分修改字段和查询字段
          paramObj.brand_id = this.currentBrand;
          paramObj.count_list = this.currentDB.id;
          paramObj.req = {
            count_list: this.currentDB.id,
          };
          // console.log(this.queryForm)
          Object.keys(this.queryForm).forEach((key) => {
            if (key == "low_label_id") {
              if (this.queryForm.low_label_id.length > 0) {
                // paramObj.low_label_id_aa = ',' + this.queryForm.low_label_id.join(',') + ','
                // paramObj.low_label_id_aa = ',' + this.queryForm.low_label_id + ','
                paramObj.req.low_label_id =
                  "," + this.queryForm.low_label_id + ",";
              }
            }
            // 更新时间 商品名称 sku名称处理 型号多选
            else if (
              // key == "update_at" ||
              // key == "create_at" ||
              // key == "goods_title" ||
              // key == "sku_att_class" ||
              // key == "sku_num" ||
              // key == "sku_status"
              this.multipleKeySettle.includes(key)
            ) {
              if (this.queryForm[key] && this.queryForm[key].length > 0) {
                paramObj.req[key] = this.queryForm[key].join(",");
              }
            } else {
              if (this.queryForm[key]) {
                // paramObj[key + '_aa'] = this.queryForm[key]
                paramObj.req[key] = this.queryForm[key];
              }
            }
          });
        } else {
          let idArr = [];
          this.selectedList.forEach((item) => idArr.push(item.id));
          paramObj.id = idArr.join(",");
        }

        const loading = this.$loading({
          lock: true,
          text: "正在删除",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        // console.log(paramObj)
        this.$api
          .deleteDataListFunc(paramObj)
          .then((res) => {
            loading.close();
            if (res.code == 0) {
              this.updateDataTag = !this.updateDataTag;
              this.utils.notificationInfo("提示", "已删除", "success");
            }
          })
          .catch((error) => {
            console.log(error);
            loading.close();
            this.$message.warning("删除失败了");
          });
      });
    },
    // 同步白名单和控价表
    syncHandle() {
      if (this.matchParams.table_id === "") {
        this.$message.warning("未选择控价表");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在同步...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let paramObj = {
        brand_id: this.currentBrand,
        type: this.matchParams.match_type,
        table_id: this.matchParams.table_id,
        count_list: this.currentDB.id,
      };

      this.$api
        .syncWhiteControl(paramObj)
        .then((res) => {
          // console.log(res)
          loading.close();
          if (res.code == 0) {
            this.utils.notificationInfo("提示", "已同步", "success");
            this.updateDataTag = !this.updateDataTag;
            this.syncVis = false;
          }
        })
        .catch((error) => {
          console.log(error);
          loading.close();
          this.$message.error("同步失败");
        });
    },
    // 查询相关
    queryHandle(e) {
      if (Array.isArray(e)) {
        if (e.includes("未打标")) {
          this.queryForm.sku_num = ["未打标"];
        }
        if (e.includes("已打标")) {
          this.queryForm.sku_num = ["已打标"];
        }
      }
      this.utils.sessionSetStore("dataListQuery", this.queryForm);
      this.utils.debounce(
        function () {
          this.updateDataTag = !this.updateDataTag;
        }.bind(this),
        500
      );
    },
    resetQuery() {
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] = "";
      });
      this.queryForm.low_label_id = "";
      this.queryForm.goods_title = [];
      this.queryForm.sku_att_class = [];
      this.queryForm.sku_num = []; // 型号多选
      this.queryForm.not_included = "1"; // 默认 1-包含
      this.queryForm.label = "0"; // 默认 1
      this.updateDataTag = !this.updateDataTag;
      this.utils.sessionSetStore("dataListQuery", this.queryForm);
    },

    // 型号输入相关
    modelSearch(str, cb) {
      var results = str
        ? this.modelList.filter(this.createFilter(str))
        : this.modelList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(str) {
      return (item) => {
        return item.value.toLowerCase().split(str).length > 1;
      };
    },

    // 标签相关方法
    // 获取标签信息
    gainLabelInfo() {
      let paramObj = {
        brand_id: this.currentBrand,
        state: "1", // 1-标签 2-型号
      };
      this.$api.getLabelFunc(paramObj).then((res) => {
        // console.log('标签',res)
        if (res.code == 0) {
          let arr = [];
          res.data.list.forEach((item) => {
            arr.push({
              label: item.label,
              value: item.id,
            });
          });
          this.labelList = arr;
        }
      });
    },
    // 删除标签
    deleteLabel(id) {
      let paramObj = {
        id,
        state: "1",
        brand_id: this.currentBrand,
      };
      this.$api
        .deleteLabelFunc(paramObj)
        .then((res) => {
          // console.log('d',res)
          if (res.code == 0) {
            this.gainLabelInfo();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("删除失败");
        });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    // 添加标签
    addLabel() {
      if (!this.inputValue) {
        this.inputVisible = false;
        return;
      }
      let paramObj = {
        brand_id: this.currentBrand,
        state: "1",
        label: this.inputValue,
      };

      this.$api
        .addLabelFunc(paramObj)
        .then((res) => {
          // console.log('添加',res)
          if (res.code == 0) {
            // this.utils.notificationInfo('消息','添加成功','success')
            this.inputVisible = false;
            this.inputValue = "";
            this.gainLabelInfo();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("添加失败");
        });
    },

    // 开启信息操作弹窗
    infoHandle(row) {
      // 初始化
      Object.keys(this.modifyForm).forEach((key) => {
        this.modifyForm[key] = "";
      });
      this.modifyForm.low_label_id = [];

      // 批量操作
      if (row == "batch") {
        if (this.selectedList.length == 0) {
          this.$message.warning("还未选择任何信息");
          return;
        }
        this.modifyForm.count_list = this.currentDB.id;
        this.modifyVis = true;
        this.isBatch = true;
      } else {
        // 单条操作
        this.modifyVis = true;
        this.isBatch = false;
        Object.keys(this.modifyForm).forEach((key) => {
          this.modifyForm[key] = row[key];
        });
        this.modifyForm.current_id = row.id;
        if (row.low_label_id) {
          this.modifyForm.low_label_id = row.low_label_id
            .split(",")
            .filter((k) => k != "");
        }
      }
    },
    // 提交修改
    subModify() {
      this.btnLoading = true;
      let paramObj = {};
      // 单条修改：型号内容不做删除，如果所填为空，不做处理。其他字段如果为空，
      //          视为删除，将空值传给后端
      // 批量修改：字段为空时，不做处理
      if (this.isBatch) {
        // 批量操作id为多个数据id拼成的字符串
        Object.keys(this.modifyForm).forEach((key) => {
          if (key == "low_label_id") {
            if (this.modifyForm[key].length > 0) {
              paramObj.low_label_id =
                "," + this.modifyForm.low_label_id.join(",") + ",";
            }
          } else {
            if (this.modifyForm[key]) {
              paramObj[key] = this.modifyForm[key];
            }
          }
        });

        if (this.selectedList == "selectAll") {
          // 勾选全部
          paramObj.brand_id = this.currentBrand;
          paramObj.cur_count_list = this.currentDB.id; // 当前所属数据库
          paramObj.req = {
            cur_count_list: this.currentDB.id,
          };

          // 勾选全部时需要将查询条件传入
          // console.log(this.queryForm)
          Object.keys(this.queryForm).forEach((key) => {
            if (key == "low_label_id") {
              if (this.queryForm.low_label_id.length > 0) {
                // 标签可多选
                // paramObj.low_label_id = ',' + this.queryForm.low_label_id.join(',') + ','
                // 标签单选
                // paramObj.low_label_id_aa = ',' + this.queryForm.low_label_id + ','
                paramObj.req.low_label_id =
                  "," + this.queryForm.low_label_id + ",";
              }
            }
            // 更新时间 商品名称 sku名称处理 型号多选
            else if (
              // key == "update_at" ||
              // key == "create_at" ||
              // key == "goods_title" ||
              // key == "sku_att_class" ||
              // key == "sku_num" ||
              // key == "sku_status"
              this.multipleKeySettle.includes(key)
            ) {
              if (this.queryForm[key] && this.queryForm[key].length > 0) {
                paramObj.req[key] = this.queryForm[key].join(",");
              }
            } else {
              if (this.queryForm[key]) {
                // paramObj[key + '_aa'] = this.queryForm[key]
                paramObj.req[key] = this.queryForm[key];
              }
            }
          });
        } else {
          let idArr = [];
          paramObj.cur_count_list = this.currentDB.id; // 当前所属数据库
          this.selectedList.forEach((item) => idArr.push(item.id));
          paramObj.id = idArr.join(",");
        }
      } else {
        // 单条修改
        Object.keys(this.modifyForm).forEach((key) => {
          if (key == "low_label_id") {
            if (this.modifyForm[key].length > 0) {
              paramObj.low_label_id =
                "," + this.modifyForm.low_label_id.join(",") + ",";
            } else {
              paramObj.low_label_id = "";
            }
          } else {
            if (key != "current_id") {
              paramObj[key] = this.modifyForm[key];
            }
          }
        });
        paramObj.id = this.modifyForm.current_id;
      }
      // console.log(paramObj)
      this.$api
        .modifyDataListFunc(paramObj)
        .then((res) => {
          this.btnLoading = false;
          // console.log(res)
          if (res.code == 0) {
            this.resetPageNum = false;
            this.updateDataTag = !this.updateDataTag;
            this.modifyVis = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$message.error("操作失败");
        });
    },

    // 更新状态
    setRPN(e) {
      this.resetPageNum = e;
    },
    // 获取批量选择结果
    getMultipleList(e) {
      this.selectedList = e;
      // console.log(this.selectedList)
    },
    // 获取控价表型号
    getModeList() {
      this.modelList = [];
      this.qModeList = [
        {
          label: "查看未打标",
          value: "未打标",
        },
        {
          label: "查看已打标",
          value: "已打标",
        },
      ];
      let paramObj = {
        brand_id: this.currentBrand,
      };
      this.$api.getControlPriceFunc(paramObj).then((res) => {
        // console.log(res)
        let aSet = new Set();
        res.data.list.forEach((item) => {
          aSet.add(item.sku_num);
        });
        // 型号筛选
        aSet.forEach((item) => {
          this.modelList.push({
            value: item,
          });
          this.qModeList.push({
            value: item,
            label: item,
          });
        });
      });
    },
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.currentBrand,
        count_list: this.currentDB.id,
        req: {},
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (key == "low_label_id") {
          if (this.queryForm.low_label_id.length > 0) {
            // 标签可多选
            // paramObj.low_label_id = ',' + this.queryForm.low_label_id.join(',') + ','
            // 标签单选
            paramObj.req.low_label_id = "," + this.queryForm.low_label_id + ",";
          }
        } else if (
          // key == "update_at" ||
          // key == "create_at" ||
          // key == "goods_title" ||
          // key == "sku_att_class" ||
          // key == "sku_num" ||
          // key == "sku_status"
          this.multipleKeySettle.includes(key)
        ) {
          if (this.queryForm[key] && this.queryForm[key].length > 0) {
            paramObj.req[key] = this.queryForm[key].join(",");
          }
        } else {
          if (this.queryForm[key]) {
            paramObj.req[key] = this.queryForm[key];
          }
        }
      });
      return {
        apiName: "getDataListFunc",
        paramObj,
        success: function (res, callBack) {
          let records = res.data.list;
          let arr = [];
          records.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                item[key] == null ||
                item[key] == undefined ||
                item[key] == "null" ||
                item[key] == "undefined"
              ) {
                item[key] = "";
              }
              // if(key == 'sku_num' && (item[key] == '' || item[key] == '00')) {
              //     // 没有型号显示 未打标
              //     item[key] = '未打标'
              // }
            });

            // 由于已下架商品取不到商品链接，自行进行商品链接设置
            item.goods_url_ult = this.utils.setGoodsUrl(item);

            arr.push(item);
          });
          this.dataList = arr;

          this.totalCount = res.data.count;

          // 获取当前页所有型号信息
          this.dataList.forEach((item) => {
            // 标签内容转换
            item.tag = [];
            if (item.low_label_id) {
              // low_label_id的格式为 ",17,18,19,"  需要将前后“，”去除
              let l_arr = item.low_label_id.split(",").filter((k) => k != "");
              l_arr.forEach((l) => {
                let records = this.labelList.filter((el) => el.value == l)[0];
                // 如果标签已在管理页中被删除，就显示标签id
                if (records) {
                  item.tag.push(records.label);
                } else {
                  item.tag.push(l);
                }
              });
            }

            // 低价状态展示处理
            const low = this.isLowStatusOptions.filter(
              (el) => el.value == item.is_low
            )[0];
            item.is_low_obj = low || {
              className: "fontColor_info",
              label: "未知",
            };
            // 链接状态展示处理
            const lin = this.linkStatusOptions.filter(
              (el) => el.value == item.link_status
            )[0];
            item.link_status_obj = lin || {
              className: "fontColor_info",
              label: "未知",
            };
            // SKU状态展示
            // sku_status  SKU状态    0-正常   1-无效  2-新增
            const skuSta = this.skuStatusOptions.filter(
              (el) => el.value == item.sku_status
            )[0];
            item.sku_status_obj = skuSta || {
              className: "fontColor_info",
              label: "未知",
            };
            // 更新状态
            const isUp = this.isUpdateOptions.filter(
              (el) => el.value == item.is_update
            )[0];
            item.is_update_obj = isUp || {
              className: "fontColor_info",
              label: "未知",
            };
          });

          if (callBack) {
            callBack();
          }
        }.bind(this),
        failed: function (error, callBack) {
          if (callBack) {
            callBack();
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.g_info {
  display: flex;
  .g_pic {
    .el-image {
      width: 70px;
      height: 90px;
      // margin-top: 20px;
      i {
        font-size: 20px;
        line-height: 50px;
      }
    }
  }

  .g_right {
    padding-left: 10px;
    .g_plt {
      width: 50px;
      margin-top: 10px;
    }
  }
}
.g_title {
  text-align: left;
}

// 标签管理窗口
.label_main {
  .l_title {
    font-size: 15px;
  }
  .l_box {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    .label_items {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  .add_box {
    margin-left: 10px;

    .input-new-tag {
      width: 140px;
    }
  }
}

// 信息操作窗口
.operate_area {
  padding: 20px;
  text-align: center;

  .tips {
    margin-top: -30px;
    margin-bottom: 50px;
  }
  .op_items {
    min-height: 50px;
    margin-bottom: 10px;

    .op_label {
      display: inline-block;
      width: 100px;
      height: 100%;
      font-size: 14px;
      line-height: 50px;
      margin-right: 10px;
      text-align: right;
    }
    .input {
      width: 345px;
      line-height: 44px;
    }
    // 匹配窗口
    .match_input {
      line-height: 44px;
    }
  }
}

// 表格中标签样式
.tag_style {
  .el-tag {
    margin: 2px;
  }
}

// 修改图标样式
.edit_icon {
  /deep/ i {
    font-size: 20px;
  }
}

.tip_btn {
  width: 200px;
  .tip_items {
    margin: 3px 0;
    .t_title {
      font-weight: 900;
      font-size: 13px;
    }
  }
}
.icon {
  /deep/ i {
    font-size: 20px;
    color: #9fa0a3;
  }
}

.icon_color {
  /deep/ i {
    font-size: 20px;
    color: #409eff;
  }
}

// 自定义表头弹窗
.el-transfer {
  /deep/.el-transfer-panel {
    width: 300px;
  }
  /deep/.el-checkbox {
    margin-right: 10px;
  }

  // 上下移动按钮覆盖内容解决
  /deep/.el-checkbox-group {
    padding-bottom: 40px;
  }
}

// 选项样式
.hasChecked {
  width: 100%;
  margin: 0;
  .option_label {
  }
  .option_width {
    float: right;

    .o_w {
      border: 1px solid #fff;
      padding: 0 2px;
      &:hover {
        border: 1px solid #9fa0a3;
      }
    }
  }
}

// 上传文件按钮
.up_btn {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  .upload {
    width: 56px;
    height: 28px;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.database {
  // padding: 10px;
  display: flex;
  align-items: center;
  height: 40px;

  .cur_db {
    margin-right: 20px;
    > span {
      font-size: 16px;
      border-left: 4px solid #409eff;
      padding-left: 10px;

      > span {
        font-weight: bold;
        font-size: 16px;
      }

      /deep/ .el-select {
        line-height: 25px;
      }

      .cur_db_select {
        /deep/ .el-input__inner {
          font-weight: bold;
          font-size: 16px;
          border: none;
          color: #000;
          // width: 120px;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
      }
    }
  }
}
</style>
